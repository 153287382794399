<template>
  <div id="app">
    <AdSense></AdSense>
    <div class="container">
      <div class="title-wrapper">
        <span class="main-title">Currency Converter</span>
      </div>
      <div class="content">
        <div class="amount">
          <span class="title">Amount</span>
          <span class="symbol" ref="symbolHook">{{ baseCurrency.symbol_native }}</span>
          <input class="amount-input box" type="text" value="0" :style="indentVal" v-model="inputVal"
                 @keyup="submit($event)"/>
        </div>
        <div class="from">
          <span class="title">From</span>
          <div class="box-arrow box" @click="baseOpenList">
            <span class="box-text" :class="'fi-' + baseCurrency.code">{{ showCurrency(baseCurrency) }}</span>
          </div>
        </div>
        <div class="to">
          <span class="title">To</span>
          <div class="box-arrow box" @click="curOpenList">
            <span class="box-text" :class="'fi-' + calCurrency.code">{{ showCurrency(calCurrency) }}</span>
          </div>
        </div>
        <div class="result">
          <span class="title">Result</span>
          <div class="box">
            <span class="result-text">{{ currencyCal }}</span>
          </div>
        </div>
      </div>
    </div>
    <AdSense></AdSense>
    <transition name="list-move">
      <div class="currency-list" v-show="showList">
        <ul class="list-box">
          <li
            class="list-text"
            v-for="(item, index) in currencyData"
            :class="'fi-' + item.code + ' ' + (index === number ? 'active' : '')"
            :key="index"
            @click="selectCurrency(item, index)"
          >{{ showCurrency(item) }}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import currencyData from '../assets/data/data.json'
import AdSense from '@/components/AdSense'

export default {
  name: 'App',
  components: {
    AdSense
  },
  data() {
    return {
      inputVal: 1,
      conversion_rates: 0,
      showList: false,
      currencyData,
      baseCurrency: {
        currency_code: 'USD',
        currency_en: 'US Dollar',
        en: 'United States of America (USA)',
        code: 'us',
        symbol_native: '$'
      },
      calCurrency: {
        currency_code: 'EUR',
        currency_en: 'Euro',
        en: 'European Union',
        code: 'eu',
        symbol_native: '€'
      },
      selectLock: 0,
      indentVal: 'text-indent: 10px',
      number: 0
    }
  },
  mounted() {
    this.getRates('USD', 'EUR')
    this.textIndent()
  },
  // 计算属性
  computed: {
    currencyCal() {
      return this.calCurrency.symbol_native + ' ' + (this.inputVal * this.conversion_rates).toFixed(4)
    }
  },
  // 方法集合
  methods: {
    textIndent() {
      this.$nextTick(() => {
        this.indentVal = `text-indent: ${this.$refs.symbolHook.offsetWidth + 4}px`
      })
    },
    submit(e) {
      if (e.keyCode === 13) {
        e.preventDefault()
        document.activeElement.blur() // 关闭软键盘
      }
    },
    getRates(BaseCur, calCur) {
      this.$axios({
        method: 'get',
        url: `https://v6.exchangerate-api.com/v6/2b7fbc670b46f17e9f2531f5/latest/${BaseCur}`
      }).then((res) => {
        const result = res.data.conversion_rates
        for (const item in result) {
          if (item === calCur) {
            this.conversion_rates = result[item]
          }
        }
      })
    },
    selectCurrency(item, index) {
      this.showList = false
      if (this.selectLock) {
        this.baseCurrency.currency_code = item.currency_code
        this.baseCurrency.currency_en = item.currency_en
        this.baseCurrency.en = item.en
        this.baseCurrency.code = item.code
        this.baseCurrency.symbol_native = item.symbol_native || '$'
      } else {
        this.calCurrency.currency_code = item.currency_code
        this.calCurrency.currency_en = item.currency_en
        this.calCurrency.en = item.en
        this.calCurrency.code = item.code
        this.calCurrency.symbol_native = item.symbol_native || '$'
      }
      this.getRates(this.baseCurrency.currency_code, this.calCurrency.currency_code)
      this.textIndent()
      this.number = index
    },
    baseOpenList() {
      this.showList = true
      this.selectLock = 1
    },
    curOpenList() {
      this.showList = true
      this.selectLock = 0
    },
    showCurrency(item) {
      return (
        item.currency_code + (item.currency_en && item.en ? ` - ${item.currency_en} - ${item.en}` : ' - ' + (item.currency_en || item.en))
      )
    }
  }
}
</script>
<style scoped>
#app {
  padding: 12px;
}

.container {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
}

.title-wrapper {
  width: 100%;
  height: 55px;
  background-color: #f0f4fa;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.title-wrapper .main-title {
  font-size: 26px;
  line-height: 55px;
  font-weight: 600;
  color: #011d3f;
}

.content {
  padding: 0 25px 10px 25px;
}

.title {
  font-size: 18px;
  font-weight: 400;
  color: #011d3f;
  padding-left: 8px;
}

.amount {
  margin-top: 18px;
  position: relative;
}

.symbol {
  position: absolute;
  bottom: 39px;
  left: 10px;
}

.box {
  width: 100%;
  height: 45px;
  margin-top: 10px;
  margin-bottom: 25px;
  border-radius: 5px;
  border: 1px #ccc solid;
}

.box-arrow {
  background: url(../assets/arrow.svg) right 12px top 18px no-repeat;
  background-size: 15px 9px;
}

.amount-input {
  padding-left: 10px;
  font-size: 16px;
  box-sizing: border-box;
}

.box-text {
  font-size: 16px;
  line-height: 45px;
  padding-left: 45px;
  padding-right: 35px;
  background-repeat: no-repeat;
  background-position: 15px 15px;
  background-size: 20px 15px;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.result-text {
  font-size: 16px;
  line-height: 45px;
  padding-left: 10px;
}

.currency-list {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  overflow: auto;
}

.list-box {
  width: 100%;
  height: 55px;
}

.list-text {
  font-size: 16px;
  line-height: 45px;
  padding-left: 50px;
  padding-right: 20px;
  background-repeat: no-repeat;
  background-position: 18px 14px;
  background-size: 20px 15px;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.list-text.active {
  background-color: #e6e6e6;
}

/* 动画 */
/* Vue过度效果
  <transition name="detail-move"></transition>

进入
  .xxx-enter 过度开始的状态
  .xxx-enter-to 过度结束的状态
  .xxx-enter-active 过度持续的时间、延迟、曲线函数

离开
  .xxx-leave 过度开始的状态
  .xxx-leave-to 过度结束的状态
  .xxx-leave-active 过度持续的时间、延迟、曲线函数
*/
.list-move-enter-active,
.list-move-leave-active {
  transition: all 0.7s;
}

.list-move-enter,
.list-move-leave-to {
  opacity: 0;
}

.list-move-enter-to,
.list-move-leave {
  opacity: 1;
}
</style>
